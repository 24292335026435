html.dark {
  background-color: rgb(15, 23, 42);
}

.navtop {
   background-color: rgb(251, 205, 85);
   text-align: center;
   width: 100%;
   height: 30px;
   margin-bottom: 5px;
}

.navtop-text {
  padding: 1em;
}

.vertical-align-center {
  vertical-align: middle;
} 

.img-container {
  width: 550px;
  border-radius: 10px;
}